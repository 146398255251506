import React from 'react';

import styles from './blocks.module.scss';

export default function(props: any) {
    return (
        <div className={styles.blocks}>
            Blocks
        </div>
    )
}