import React, {useEffect, useState} from "react";

import {RgbMatrix} from '../../types/rgb_matrix';
import styles from "./rgb_matrix.module.scss";
import TransactionView from "../TransactionView";

export default function (props: RgbMatrix) {
    const matrixSize = 3;
    const pixelSize = 3;

    interface Pixel {
        matrix: number[][];
        colorStyle: string;
        colorHint: string;
    }

    const [pixels, setPixels] = useState<Pixel[]>([]);

    useEffect(() => {
        async function calculateHashPixels() {
            const hashPixels: Pixel[] = [];
            let hashBytes = fromHexString(props.hash);
            let hashPixel = hashBytesToPixel(Array.from(hashBytes));
            hashPixels.push(hashPixel);

            let prevHash = hashBytes;
            for (let i = 0; i < props.difficulty; i++) {
                prevHash = await generateSha256Hash(await generateSha256Hash(prevHash));
                const pixel = hashBytesToPixel(Array.from(prevHash));
                hashPixels.push(pixel);
            }

            setPixels([...hashPixels]);
        }

        void calculateHashPixels();
    }, [props.hash]);

    function fromHexString(hexString: string) {
        return Uint8Array.from(hexString.match(/.{1,2}/g)!.reverse().map((byte) => parseInt(byte, 16)));
    }

    function toHexString(bytes: Uint8Array) {
        return bytes.reduce((str, byte) => str + byte.toString(16).padStart(2, '0'), '');
    }

    function hashToRgbMatrix(hash: number[]): number[][] {
        const rgbMatrix: number[][] = [];
        for (let i = 0; i < matrixSize * matrixSize * pixelSize; i += pixelSize) {
            rgbMatrix.push(hash.slice(i, i + pixelSize).map(Number));
        }
        return rgbMatrix;
    }

    function RgbMatrixToAvgColor(rgbMatrix: number[][]): number[] {
        const colorsNumber = rgbMatrix.length
        const avgColor: number[] = rgbMatrix.reduce((previousValue, currentValue) => [
            previousValue[0] + currentValue[0],
            previousValue[1] + currentValue[1],
            previousValue[2] + currentValue[2],
        ]);
        avgColor[0] = Math.floor(avgColor[0] / colorsNumber);
        avgColor[1] = Math.floor(avgColor[1] / colorsNumber);
        avgColor[2] = Math.floor(avgColor[2] / colorsNumber);
        return avgColor;
    }

    function hashBytesToPixel(hashBytes: number[]): Pixel {
        const matrix = hashToRgbMatrix(hashBytes);
        const avgColor = RgbMatrixToAvgColor(matrix);
        const colorStyle = `rgb(${avgColor[0]}, ${avgColor[1]}, ${avgColor[2]})`;
        const colorHint = `#${avgColor.map(byte => byte.toString(16).padStart(2, '0')).join('')}`;
        return {matrix, colorStyle, colorHint};
    }

    async function generateSha256Hash(data: Uint8Array) {
        const hashBytes = await window.crypto.subtle.digest('SHA-256', data);
        return new Uint8Array(hashBytes);
    }

    return (
        <div className={styles.matrix}>
            <h1>{props.title}</h1>
            <div className={styles.avgColorBoxContainer}>
                {pixels.map((pixel, idx) => (
                    <div className={styles.pixelContainer} style={{flexDirection: props.rtl ? 'row' : 'row-reverse'}}>
                        <table className={styles.pixelTable}>
                            <tbody>
                            {[0, 1, 2].map(rowIndex => (
                                <tr key={rowIndex}>
                                    {[0, 1, 2].map(colIndex => {
                                        const index = rowIndex * 3 + colIndex;
                                        const [red, green, blue] = pixel.matrix[index];
                                        const hint = `#${pixel.matrix[index].map(byte => byte.toString(16).padStart(2, '0')).join('')}`;
                                        const colorStyle = {backgroundColor: `rgb(${red}, ${green}, ${blue})`};
                                        return <td key={colIndex} style={colorStyle} title={hint}></td>;
                                    })}
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        <div className={styles.avgColorView} key={pixel.colorHint + idx}>
                            <div className={styles.avgColorBox} style={{backgroundColor: pixel.colorStyle}}
                                 title={`${pixel.colorHint} ${pixel.colorStyle}`}></div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
