import React, {useEffect, useState} from 'react';

import styles from './block.module.scss';
import Client from '../../httpClient';
import Navbar from '../../components/Navbar';
import {Block} from '../../types/block';
import Loader from 'react-loader-spinner';

import dayjs from 'dayjs';
import prettyBytes from 'pretty-bytes';
import TransactionView from '../../components/TransactionView';
import {Transaction} from '../../types/transaction';
import {Link} from 'react-router-dom';
import {useBottomScrollListener} from 'react-bottom-scroll-listener';
import RgbMatrixView from "../../components/RgbMatrixView";

export default function (props: any) {

    const PAGE_SIZE = 5;
    const MAX_DIFFICULTY = 1 << 5;

    const [block, setBlock] = useState<Block | null>(null);
    const [difficulty, setDifficulty] = useState<number>(0);
    const [txs, setTxs] = useState<Array<Transaction>>(new Array<Transaction>());
    const [skip, setSkip] = useState<number>(0);
    const [loadingTx, setLoadingTx] = useState<boolean>(false);
    const [loadingBlock, setLoadingBlock] = useState<boolean>(false);

    useEffect(() => {
        const hash = props.match.params.hash;
        setLoadingBlock(true);
        setSkip(0);
        setTxs(new Array<Transaction>());
        Client.Blocks.get(hash).then(b => {
            setBlock(b);
            setDifficulty(calculateDifficulty(b));
            setLoadingBlock(false);
        }).catch(err => {
            if (err) {
                setLoadingBlock(false);
                throw err;
            }
        });
    }, [props.match.params.hash]);

    useEffect(() => {
        if (block && skip < block.tx.length) fetchTransactions(skip);
    }, [block, skip]);

    function fetchTransactions(skip: number) {
        setLoadingTx(true);
        const list = block!.tx.slice(skip, skip + PAGE_SIZE);
        Client.Transactions.batch(list).then(data => {
            const newTxs = txs!.concat(data);
            setTxs(newTxs);
            setLoadingTx(false);
        }).catch(err => {
            if (err) {
                setLoadingTx(false);
                throw err;
            }
        });
    }

    function compactToBig(bits: string) {
        const compact = parseInt(bits, 16);
        // Extract the mantissa, sign bit, and exponent.
        let mantissa = compact & 0x007fffff;
        const isNegative = (compact & 0x00800000) !== 0;
        const exponent = compact >>> 24;

        // Since the base for the exponent is 256, the exponent can be treated
        // as the number of bytes to represent the full 256-bit number. So,
        // treat the exponent as the number of bytes and shift the mantissa
        // right or left accordingly.
        let bn;
        if (exponent <= 3) {
            mantissa >>>= 8 * (3 - exponent);
            bn = BigInt(mantissa);
        } else {
            bn = BigInt(mantissa);
            for (let i = 0; i < 8 * (exponent - 3); i++) {
                bn = bn * BigInt(2);
            }
        }

        // Make it negative if the sign bit is set.
        if (isNegative) {
            bn = -bn;
        }

        return bn;
    }

    function bigIntToBigEndianBytes(bigInt: bigint) {
        // Handle the special case for 0.
        if (bigInt === BigInt(0)) {
            return new Uint8Array([0]);
        }

        let bytes = [];
        while (bigInt > 0) {
            // Get the current byte.
            const byte = bigInt % BigInt(256);
            // Insert the byte at the start of the array to keep the results in big-endian order.
            bytes.unshift(Number(byte));
            // Shift right by one byte to proceed to the next.
            bigInt /= BigInt(256);
        }

        // Convert to Uint8Array and return.
        return new Uint8Array(bytes);
    }

    function calculateDifficulty(block: Block) {
        const diffBn = compactToBig(block!.bits);
        const diffBytes = bigIntToBigEndianBytes(diffBn);
        return MAX_DIFFICULTY - diffBytes.length;
    }

    const onScrollEnd = () => setSkip(skip => skip + PAGE_SIZE);
    useBottomScrollListener(onScrollEnd);

    return (
        <>
            {block && <div className={`page ${styles.block}`}>
                <div className="details">
                    <h1>Block #{block?.height}</h1>
                    <div className="table">
                        <div className="element">
                            <div className="key">
                                Hash
                            </div>
                            <div className="value">
                                {block?.hash}
                            </div>
                        </div>
                        <div className="element">
                            <div className="key">
                                Confirmations
                            </div>
                            <div className="value">
                                {block?.confirmations}
                            </div>
                        </div>
                        <div className="element">
                            <div className="key">
                                Timestamp
                            </div>
                            <div className="value">
                                {dayjs(block?.time! * 1000).format("YYYY-MM-DD HH:MM:ss")}
                            </div>
                        </div>
                        <div className="element">
                            <div className="key">
                                Height
                            </div>
                            <div className="value">
                                {block?.height}
                            </div>
                        </div>
                        <div className="element">
                            <div className="key">
                                Number of transactions
                            </div>
                            <div className="value">
                                {block?.tx.length}
                            </div>
                        </div>
                        <div className="element">
                            <div className="key">
                                Difficulty
                            </div>
                            <div className="value">
                                {block?.difficulty}
                            </div>
                        </div>
                        <div className="element">
                            <div className="key">
                                ChemCrypt Difficulty
                            </div>
                            <div className="value">
                                {difficulty}
                            </div>
                        </div>
                        <div className="element">
                            <div className="key">
                                Merkle root
                            </div>
                            <div className="value">
                                {block?.merkleroot}
                            </div>
                        </div>
                        <div className="element">
                            <div className="key">
                                Version
                            </div>
                            <div className="value">
                                {block?.version}
                            </div>
                        </div>
                        <div className="element">
                            <div className="key">
                                Bits
                            </div>
                            <div className="value">
                                {block?.bits}
                            </div>
                        </div>
                        <div className="element">
                            <div className="key">
                                Weight
                            </div>
                            <div className="value">
                                {block?.weight} WU
                            </div>
                        </div>
                        <div className="element">
                            <div className="key">
                                Size
                            </div>
                            <div className="value">
                                {prettyBytes(block?.size!)}
                            </div>
                        </div>
                        <div className="element">
                            <div className="key">
                                Virtual size
                            </div>
                            <div className="value">
                                {prettyBytes(block?.strippedsize!)}
                            </div>
                        </div>
                        <div className="element">
                            <div className="key">
                                Nonce
                            </div>
                            <div className="value">
                                {block?.nonce}
                            </div>
                        </div>
                        <div className="element">
                            <div className="key">
                                Previous block
                            </div>
                            <div className="value">
                                <Link to={"/block/" + block?.previousblockhash}>{block?.previousblockhash}</Link>
                            </div>
                        </div>
                    </div>
                    <h1>Pixelation</h1>
                    {!loadingBlock && <div className={styles.pixelation}>
                        <RgbMatrixView hash={block.hash} title={"Block hash"} difficulty={difficulty}
                                       avg_color_title={"Mined color"} rtl={true}/>
                        <RgbMatrixView hash={block.merkleroot} title={"Merkle root hash"} difficulty={difficulty}
                                       avg_color_title={"Target color"} rtl={false}/>
                    </div>}
                    {loadingBlock && <div className="loader-container small">
                        <Loader
                            type="ThreeDots"
                            color="#1a1919"
                            height={100}
                            width={100}
                        />
                    </div>}
                    <h1>Transactions ({block.tx.length})</h1>
                    {txs && <div>
                        {txs!.map((tx, i) => (
                            <TransactionView hash={tx.txid} vin={tx.vin} vout={tx.vout} key={i}/>
                        ))}
                    </div>}
                    {loadingTx && <div className="loader-container small">
                        <Loader
                            type="ThreeDots"
                            color="#1a1919"
                            height={100}
                            width={100}
                        />
                    </div>}
                </div>
            </div>}
            {loadingBlock && <div className="loader-container">
                <Loader
                    type="ThreeDots"
                    color="#1a1919"
                    height={100}
                    width={100}
                />
            </div>}
        </>
    )
}